.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #181818;

  .containerImage {
    height: 100vh;
    @media screen and (max-width: 770px) {
      animation: slide 500s linear infinite;
    }

    @keyframes slide {
      0% {
        transform: translate3d(0, 0, 0);
      }
      100% {
        transform: translate3d(-1020px, 0, 0);
      }
    }
  }

  &:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 1;
  }
}

.formIcon {
  margin-right: 15px;
  opacity: 0.8;
}

.title {
  margin-bottom: 15px !important;
  font-weight: 700 !important;
  @media screen and (max-width: 770px) {
    font-size: 1.725rem !important;
  }
}

.content {
  margin: 10px 0 !important;
}

.intakeContainer {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  align-items: center;
}

.intake {
  margin-bottom: 350px;
  margin-left: 150px;
  max-width: 400px;
  width: 100%;
  padding: 20px 30px;

  @media screen and (max-width: 770px) {
    margin-left: unset;
    margin-bottom: unset;
    width: unset;
    max-width: unset;
  }
}

.footerButtons {
  padding-top: 15px;
  text-align: right;
}

.goBackFooter {
  text-align: center;
  padding-top: 15px;
  font-size: 14px;

  a {
    text-decoration: none;
  }
}
