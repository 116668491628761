.infoCard {
  border-left: 5px solid #2d7789;
  margin: 0 auto 30px;

  .dismissButton {
    float: right;
    margin-left: 5px;
  }

  > div {
    padding: 16px !important;

    p:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
