@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/Averta-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Averta", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;

  a {
    text-decoration: none;
  }
}

body.app--ready {
  background: #f9fafc;
  color: #333333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiFormLabel-root {
  font-family: "Averta", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
