.footer {
  // text-align: center;
  // max-width: fit-content;
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: 50px;
  // margin-bottom: 100px;
  background: white;
  padding: 50px 20px;
  position: relative;
  z-index: 99;
  border-top: 1px solid #eeeeee;

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.copyrights {
  font-size: 15px;
}

.info {
  font-size: 12px;
  margin: 0;

  a {
    color: #333333;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.link {
  display: block;
  margin-bottom: 8px !important;
  color: #333333 !important;
  opacity: 0.6;

  &:hover {
    opacity: 0.8;
  }
}

.subfooterContainer {
  padding: 30px 0 0;
}

.subfooter {
  margin-bottom: 10px;
  font-size: 10px;
  opacity: 0.7;
  transition: opacity 150ms ease-in-out;
  text-align: center;

  .dutchFlag {
    opacity: 0.5;
  }

  &:hover {
    opacity: 1;

    .dutchFlag {
      opacity: 1;
    }
  }
}

.loveIcon,
.coffeeIcon {
  vertical-align: middle;
  margin: 0 2px;
  font-size: 20px !important;

  &.loveIcon {
    color: #ff6464;
  }

  &.coffeeIcon {
    color: #a98383;
  }
}

.dutchFlag {
  width: 20px;
  vertical-align: middle;
  border-radius: 3px;
  display: inline-block;
  margin-left: 5px;
  transition: opacity 150ms ease-in-out;
}
