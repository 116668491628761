.cardChipHeader {
  position: absolute;
  right: 0;
  top: 50px;
  font-size: 15px !important;
  padding: 16px 12px !important;
}

.sitesHeader {
  display: flex;
  align-items: center;
}

.sitesHeaderButton {
  flex-grow: 1;
  text-align: right;
}

.inputContainer {
  margin-top: 30px !important;
}

.input {
  margin: 0 0 10px !important;
}

.card {
  margin: 20px 0;
}

.cardChip {
  font-size: 15px;
  padding: 16px 12px;
  margin-left: 10px;
}

.cardContent {
  padding-top: 5px !important;
  padding-bottom: 10px !important;
}

.wordPressIcon {
  width: 20px;
  vertical-align: middle;
  opacity: 0.5;
  margin-left: 10px;
}

.siteNotActive {
  vertical-align: baseline;
  position: relative;
  text-align: center;

  svg,
  img {
    path {
      fill: #ef790c;
    }
  }

  &:before {
    content: " ";
    position: absolute;
    display: block;
    background: #ef790c;
    width: 0;
    height: 0;
    left: 50%;
    top: 50%;
    z-index: 99;
    border-radius: 100%;
    animation: pulse-anim 10s infinite;
  }
}

.spacer {
  position: relative;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  display: inline-block;

  &:before {
    content: " ";
    position: absolute;
    top: -5px;
    width: 2px;
    height: 20px;
    background: #e5e5e5;
    left: 4px;
  }
}

@keyframes pulse-anim {
  0% {
    width: 0;
    height: 0;
    left: 50%;
    top: 50%;
    opacity: 0;
  }

  20% {
    width: 40px;
    height: 40px;
    left: 0;
    top: 0;
    opacity: 0.3;
  }

  30% {
    width: 20px;
    height: 20px;
    left: 12px;
    top: 12px;
    opacity: 0.2;
  }

  40% {
    width: 40px;
    height: 40px;
    left: 0;
    top: 0;
    opacity: 0.25;
  }

  70% {
    width: 0;
    height: 0;
    left: 50%;
    top: 50%;
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.upgradeButton {
  display: block;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  margin: 15px 10px;
  padding: 15px;
  background-image: linear-gradient(
    -10deg,
    rgb(255 167 0 / 21%),
    rgb(255 110 0 / 11%) 55%
  );
  color: #a5761c;
  position: relative;
  text-decoration: none;
  transition: all 150ms ease-in-out;
  min-width: 200px;

  &:hover,
  &:active {
    border-color: #c8c1b5;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  }
}

.responsiveApp {
  .collapseButton {
    position: absolute;
    right: -15px;
    bottom: 10px;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 100%;
    text-align: center;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
    line-height: 40px;
    cursor: pointer;
    z-index: 9;

    svg {
      transition: all 250ms ease-in-out;
      transform: rotate(180deg);
    }

    @media screen and (max-width: 770px) {
      display: none;
    }
  }

  .appMenu {
    transition: all 250ms ease-in-out;

    @media screen and (min-width: 769px) {
      position: fixed;
      top: 61px;
      left: 0;
      bottom: 0;

      background: white;
      box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);

      padding-top: 5px;
      max-width: 270px;
      width: 100%;
      z-index: 9;
    }

    .content {
      min-width: 200px;
      overflow: hidden;
    }
  }

  .pageContent {
    transition: all 250ms ease-in-out;
    padding-bottom: 200px;

    @media screen and (min-width: 769px) {
      padding-left: 270px;
    }
  }

  &.collapsed {
    .appMenu {
      @media screen and (min-width: 769px) {
        max-width: 80px;
      }

      .collapseButton {
        svg {
          transform: rotate(0deg);
        }
      }
    }
    .pageContent {
      @media screen and (min-width: 769px) {
        padding-left: 80px;
      }
    }
  }
}
