.accountButton {
  border-radius: 5px;
  padding: 5px 8px;

  .avatar {
    min-width: 40px !important;

    div {
      border-radius: 5px;
      width: 30px;
      height: 30px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1100px) {
    .fullName {
      display: none;
    }
  }
}
