.logo {
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  line-height: 60px;

  .backBtn {
    height: 60px;
    border-radius: 0;

    .logo {
      min-width: unset;
    }
  }
}

.divider {
  position: relative;
  width: 10px;
  height: 60px;
  margin: 0 5px;

  &:before {
    content: " ";
    position: absolute;
    top: 15px;
    width: 2px;
    height: 30px;
    background: #e5e5e5;
    left: 4px;
  }
}

.siteSelector {
  flex-shrink: 0;
  width: 230px;
  padding-left: 8px;
}

.whiteSpace {
  flex-grow: 1;
}

.planInfo {
  margin: 0 15px;
}
