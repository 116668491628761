.logo {
  margin: 30px auto 30px;
  max-width: 210px;
  display: block;

  &.logoSmall {
    max-width: 200px;
  }

  &.logoIcon {
    max-width: 45px;
  }

  @media screen and (min-width: 769px) {
    margin: 40px auto 40px;
    max-width: 300px;
  }
}

.logoAppConnect {
  padding: 15px 30px 15px;
  background: white;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);

  .container {
    margin: 0 auto;
    max-width: 480px;
  }
}

.appIcon {
  width: 40px;

  &.appShopify {
    color: #96bf48;
  }

  &.appWordPress {
    color: #424242;
  }
}

.onboardingContainer {
  max-width: 500px;
  margin: 0 auto;
}

.welcomeContainer {
  padding: 0 20px 20px;

  @media screen and (min-width: 769px) {
    padding: 40px 20px;
  }

  > p {
    font-size: 16px;
    line-height: 25px;
  }

  .input {
    margin-bottom: 15px;
  }
}

.searchBarContainer {
  margin-bottom: 15px;
  background: white;

  fieldset {
    border-left: none;
    border-right: none;
  }
  .searchBarInput {
    border-radius: 0;
  }

  .newSiteButton {
    border-radius: 0;
    height: 100%;
    box-shadow: none;
  }
}

.welcomeCardOauth {
  text-align: center;

  p {
    margin: 50px;
    line-height: 30px;
  }
}

.welcomeCodeBlock {
  padding: 0 !important;
  max-width: 540px !important;

  textarea {
    border: 0;
    margin: 0;
    padding: 25px;
  }
}

.welcomeCardContainer {
  margin: 20px auto;
  max-width: 550px;
}

.welcomeCard {
  background: #ffffff;
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-bottom-width: 2px;
  margin: 20px auto;
  max-width: 480px;
  border-radius: 5px;

  @media screen and (min-width: 769px) {
    padding: 30px;
  }

  h1 {
    margin: 0;

    @media screen and (max-width: 768px) {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 768px) {
    p {
      margin-bottom: 5px;
      font-size: 15px;
    }
  }
}

.forgotPasswordLink {
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
}

.error {
  color: #ef790c !important;
  // padding: 10px 0;
  // min-height: 21px;
}

.submitButton {
  margin-top: 30px !important;
}

.oauthBottomLink {
  text-align: center;
  padding-top: 40px;
}
