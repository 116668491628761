@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");

.App {
  position: relative;
  overflow: hidden;

  .AppContent {
    min-height: calc(100vh - 200px);
  }

  &.authenticated {
    .AppContent.CanHavePadding {
      padding-top: 62px;
    }
  }
}
