.container {
  height: calc(100vh - 171px);
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.loginActive {
    height: calc(100vh - 171px - 136px);
  }
}

.bubbleDecoration {
  .bubbleLeft,
  .bubbleRight,
  .bubbleBottom {
    border-radius: 100%;
    display: block;
    position: absolute;
    width: 450px;
    height: 450px;
    z-index: -1;
  }

  .bubbleLeft {
    background: rgba(45, 119, 137, 0.05);
    top: -170px;
    left: -360px;
    width: 800px;
    height: 800px;
  }

  .bubbleRight {
    background: rgba(240, 132, 25, 0.05);
    top: 160px;
    left: calc(50% + 350px);
  }

  .bubbleBottom {
    background: rgba(45, 119, 137, 0.05);
    top: 440px;
    left: calc(50% + 260px);
    width: 350px;
    height: 350px;
  }
}
