.notificationContainer {
  margin-bottom: 25px;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .notificationAction {
      text-align: right;
    }
  }
}
