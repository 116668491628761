.container {
  text-align: center;
  height: 60px;
  padding: 20px;

  &.planLimitReached {
    background: #348396;
    color: white;

    > span {
      display: block;
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  &.notActive {
    background: #cae0e7;

    .iconContainer {
      background: white;
      border-radius: 100%;
      width: 35px;
      height: 35px;
      display: block;
      line-height: 42px;
      margin: 0 auto;

      svg {
        width: 20px;
        height: 20px;
        color: #ef790c;
      }
    }

    .info {
      padding-top: 10px;
      font-size: 13px;
      color: #348396;
    }
  }

  &.screenshotContainer {
    background-size: 260px;
    background-color: #e4ddd6;
    background-image: url("https://cdn.buttonizer.io/images/widget-pattern.svg");
  }
}
