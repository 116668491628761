.header {
  background: white;
  padding: 0px;
  position: relative;
  z-index: 9;
  border-bottom: 1px solid #e7e7e7;

  &.unauthenticated {
    margin-bottom: 25px;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
    border-bottom: 0;

    @media screen and (min-width: 900px) {
      padding: 10px 20px;
      margin-bottom: 50px;
    }

    &.noPadding {
      margin-bottom: 0 !important;
    }

    &.onPublicPricing {
      box-shadow: unset;
    }
  }

  &.authenticated {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
  }

  .container {
    display: flex;
    align-items: center;

    &.unauthenticated {
      max-width: 1350px;
      margin: 0 auto;
    }

    .logo {
      flex-grow: 0;
      flex-shrink: 0;
      width: 75px;
      text-align: center;

      a {
        display: block;
        border: 0;
      }

      img {
        width: 55px;
      }
    }

    .menu {
      margin-left: 15px;
      flex-grow: 1;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        @media screen and (max-width: 900px) {
          display: none;
        }

        li {
          display: inline-block;
          margin: 0;
          padding: 0;

          a {
            color: #303030;
            font-weight: 400;
            padding: 15px 24px;
            font-size: 15px;
            display: inline-block;
            font-family: "Montserrat", sans-serif;
            transition: color 150ms ease-in-out;

            &:hover {
              color: #2f7789;
            }
          }

          &.active {
            a {
              color: #f08419;
              font-weight: 500;
            }
          }
        }
      }
    }

    .headerActions {
      flex-shrink: 0;
      vertical-align: middle;

      .secondaryActionButton {
        color: #303030;
        font-weight: 300;
        padding: 15px 18px;
        font-size: 15px;
        display: inline-block;
        font-family: "Montserrat", sans-serif;
        transition: color 150ms ease-in-out;

        &.active,
        &:hover {
          color: #2f7789;
        }
      }

      .actionButton {
        font-weight: 300;
        padding: 10px 20px 10px 15px;
        font-size: 15px;
        display: inline-block;
        font-family: "Montserrat", sans-serif;
        border: 2px solid #f08419;
        color: #f08419;
        border-radius: 35px;
        margin-left: 15px;
        transition: all 150ms ease-in-out;
        background: none;
        cursor: pointer;

        &.active,
        &:hover {
          border-color: #2f7789;
          color: #2f7789;
        }

        svg {
          vertical-align: middle;

          &.newAccountIcon {
            margin-right: 15px;
          }

          &.expandIcon {
            margin-left: 5px;
          }
        }

        &.myAccount {
          padding: 10px 15px 10px 20px;
        }
      }
    }
  }
}

.blackFridayBar {
  background: #1c1c1c;
  color: white;
  padding: 15px;
  text-align: center;

  b {
    color: #ec6f59;
  }

  a {
    display: inline-block;
    color: #ffffff;
    padding: 2px 5px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 25px;
    font-size: 12px;
  }

  &.celebration {
    background: #d7e5e9;
    color: #111111;

    a {
      color: #111111;
    }
  }
}
