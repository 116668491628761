.loading {
  padding: 100px 0;
  text-align: center;
  display: block;
}

.loadingText {
  margin-top: 30px !important;
  margin-bottom: 10px !important;
}
