.container {
  display: flex;
  font-size: 12px;
  color: #747474;
  align-items: center;
  transition: all 250ms ease-in-out;

  &.counterBelow {
    display: block;

    .planBar {
      width: unset;
    }
  }

  .planName {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px 10px 10px 0;
  }

  .planBar {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 5px;
    width: 100px;

    .planBarContainer {
      height: 10px;
      border-radius: 15px;
      background: #e8e8e8;
      overflow: hidden;

      .planBarState {
        border-radius: 15px;
        background: #ff7d00;
        height: 10px;

        &.planBarReachedLimit {
          background: #ff0000;
        }
      }
    }
  }

  &.fullWidth {
    .planBar {
      width: 100% !important;
    }
  }

  .usage {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px 15px 10px 10px;

    .questionmark {
      display: inline-block;
      margin-left: 5px;
      color: #007a8c;
    }

    .inlineSubscriptionButton {
      height: unset;
      font-size: 12px;
      margin-left: 12px;
      padding: 7px 10px;
      line-height: unset;
    }
  }
}

.styledPopover {
  > div {
    padding: 15px;
    color: #007a8c;
    font-size: 14px;
    max-width: 370px;
    left: 12px !important;

    .title {
      margin-bottom: 5px;
    }

    b {
      font-weight: 800;
    }

    .buttons {
      margin-top: 15px;

      .closeButton {
        margin-left: 15px;
      }
    }
  }
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  // width: 430px;
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10000;
  transition: opacity 250ms ease-in-out;

  &.shown {
    opacity: 1;
    visibility: visible;
  }
}
