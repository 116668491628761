.logo {
  max-width: 250px;
  margin: 0 auto 25px;
  display: block;
}

.errorContainer {
  margin: 25px auto;
  max-width: 530px;
  padding: 25px;

  h2 {
    margin: 0;
  }
}

.paper {
  padding: 25px;
}
